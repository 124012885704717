<template>
  <v-card elevation="1" tile class="pa-5">
    <v-row>
      <v-col cols="12" md="10" class="subtitle-1 font-weight-bold mb-5">
        INFORME RECLAMOS
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          :items="dropPage"
          v-model="rowsPage"
          label="Registros por página"
          outlined
          dense
        ></v-select>
      </v-col>
    </v-row>

    <DataTables
      ref="tablesExportar"
      :claims="claims"
      :loading="page.loading"
      :importExcel="true"
      :showSearchBank="true"
      :showSearchState="true"
      :showSearchDate="true"
      :showDaysLeft="false"
      :showTipology="true"
      :showSearchDefender="true"
      :showSearchTypolies="true"
      :showNameDefender="true"
      :itemsPerPage="rowsPage"
      :changePageFilter="true"
      @gotoClaim="gotoClaim"
      @changePage="changePage"
      @search="search"
    />
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
import currentDashboardMixin from "@/mixins/currentDashboard";

import claimsService from "@/services/claims";

import DataTables from "@/components/WFSite/Claims/DataTables";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentClaimSelectedMixin,
    currentDashboardMixin,
  ],
  components: { DataTables },
  data() {
    return {
      claims: [],
      rowsPage: 100,
      dropPage: [
        {
          value: 100,
          text: "100",
        },
        {
          value: 200,
          text: "200",
        },
        {
          value: 300,
          text: "300",
        } /*
        {
          value: 2000,
          text: "2000",
        },*/,
      ],
    };
  },
  methods: {
    async gotoClaim(selectedClaimInfo) {
      await this.loadClaimForWf(
        selectedClaimInfo.claimId,
        selectedClaimInfo.userIdentification,
        this.$route.name
      );
      this.gotoView("claimDetailInWf");
    },
    async getClaims(requiredPageNumber, filter) {
      this.initSignalLoading();

      let pageNumber = requiredPageNumber ? requiredPageNumber : 1;
      if (pageNumber > 1) this.loadDashboard();

      const sort = {
        key: "f",
        orderType: 1,
      };

      const currentPageData = this.currentPage;

      claimsService
        .getByMacroStatus(
          this.allConstants.dashboard.groups.ddcExecutive.claimsReport.statuses,
          pageNumber,
          this.rowsPage,
          filter,
          sort
        )
        .then((getResult) => {
          this.claims = getResult.data.claims;

          currentPageData.totalPages = getResult.data.pagesTotal;
          currentPageData.totalRecords = getResult.data.recordsTotal;

          currentPageData.routerName = this.$route.name;
          currentPageData.pageNumber = pageNumber;

          this.createOrUpdateCurrentPage(currentPageData);
          this.stopSignalLoading();
        })
        .catch(() => {
          this.claims = [];
          currentPageData.totalPages = 0;
          currentPageData.totalRecords = 0;

          currentPageData.routerName = this.$route.name;
          currentPageData.pageNumber = pageNumber;
          this.createOrUpdateCurrentPage(currentPageData);
          this.stopSignalLoading();
        });

      /*
      const getResult = await claimsService.getByWfStatuses(
        [filter.shift().value],
        pageNumber,
        100,
        "",
        filter
      );

      this.claims = getResult.data.claims;

      const currentPageData = this.currentPage;
      currentPageData.routerName = this.$route.name;
      currentPageData.pageNumber = pageNumber;
      currentPageData.totalPages = getResult.data.pagesTotal;
      currentPageData.totalRecords = getResult.data.recordsTotal;
      this.createOrUpdateCurrentPage(currentPageData);

      this.stopSignalLoading();
      */
    },
    search(filter) {
      this.claims = [];
      this.getClaims(1, filter);
    },
    changePage(change) {
      this.claims = [];
      this.getClaims(change.toPageNumber, change.filter);
    },
  },
  beforeMount() {
    //this.getClaims(pageNumber, []);
  },
};
</script>

<style lang="scss" scoped></style>
